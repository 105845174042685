import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Hero from '../components/Hero';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Gallery from '@browniebroke/gatsby-image-gallery';
import { motion } from 'framer-motion';

const ProjectPage = styled.section`
  .content-section-wrapper {
    background: var(--white);
    max-width: 1280px;
    width: 95%;
    margin: 5rem auto 10rem;
    h2 {
      text-align: center;
    }
  }
  .content-section,
  .gallery-section {
    padding-top: 50px;
    }
    .detail-grid {
      padding-top: 40px;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      gap: 30px;
      max-width: 768px;
      text-align: center;
      @media (min-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }
      .detail-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 250px;
        p {
          margin: 0;
          padding-top: 1.5rem;
        }
      }
    }
    .brands-wrapper {
      padding-top: 50px;
    }
    .brand-grid {
      display: flex;
      padding-top: 40px;
      justify-content: center;
    }
  }
`;

const GalleryGridWrapper = styled.div`
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: content;
  max-width: 768px;
  width: 100%;
  > div {
    justify-content: ${(props) => (props.images > 3 ? 'flex-start' : 'center')};
    margin-right: 0;
    margin-left: 0;
  }
  /* @media screen and (min-width: 576px) {
    > div {
      justify-content: ${(props) =>
    props.images > 3 ? 'flex-start' : 'center'};
      margin-right: 0;
      margin-left: 0;
    }
  } */
`;

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.15,
    },
  },
};
const brandVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.2,
    },
  },
};

const galleryVariants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};
const MotionGalleryGridWrapper = motion(GalleryGridWrapper);

export default function SingleProjectPage({ data: { project, location } }) {
  const materials = project.materials_used.map((material) => (
    <p key={material.id}>{material.name}</p>
  ));
  const fabrications = project.fabrication.map((fabrication) => (
    <p key={fabrication.id}>{fabrication.name}</p>
  ));
  const brands = project.brands.map((brand, index) => {
    const image = getImage(brand.logoImage.asset.gatsbyImageData);
    return (
      <motion.div variants={brandVariants} key={index}>
        <a href={brand.url} title={`Visit ${brand.name}'s website`}>
          <GatsbyImage image={image} alt={`${brand.name}'s Brand Logo`} />
        </a>
      </motion.div>
    );
  });

  const images = project?.galleryImages.map((image) => image.asset);
  return (
    <>
      <SEO
        title={`${project.name} Project`}
        description={project.description}
        image={project.headerImage?.asset?.url}
      />
      <ProjectPage>
        <Hero
          bg={project}
          heading={project.name}
          subText={project.description}
          bgAlt={project.headerImage.altText}
          location={{ pathname: `/${project.name}` }}
        />
        <div className="content-section-wrapper">
          <div className="content-section">
            <div className="content-wrapper">
              <motion.div
                className="detail-grid"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
                className="project-details-wrapper"
              >
                <motion.h2 variants={itemVariants}>Project Details</motion.h2>
                <motion.div
                  className="detail-grid"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true }}
                >
                  <motion.div
                    variants={itemVariants}
                    className="detail-item location"
                  >
                    <StaticImage
                      className="icon"
                      src="../images/location.png"
                      alt="location pin icon"
                      layout="fixed"
                      height={76}
                    />
                    <p>
                      {project.address[0].city}, {project.address[0].state}
                    </p>
                  </motion.div>
                  <motion.div
                    variants={itemVariants}
                    className="detail-item materials"
                  >
                    <StaticImage
                      className="icon"
                      src="../images/materials.png"
                      alt="Materials Icon"
                      layout="fixed"
                      height={76}
                    />
                    {materials}
                  </motion.div>
                  <motion.div
                    variants={itemVariants}
                    className="detail-item fabrications"
                  >
                    <StaticImage
                      className="icon"
                      src="../images/fabrication.png"
                      alt="Fabrication Icon"
                      layout="fixed"
                      height={76}
                    />
                    {fabrications}
                  </motion.div>
                  <motion.div
                    variants={itemVariants}
                    className="detail-item general-contractor"
                  >
                    <StaticImage
                      className="icon"
                      src="../images/general-contractor.png"
                      alt="General Contractor Icon"
                      layout="fixed"
                      height={76}
                    />
                    <p>{project.general_contractor[0].name}</p>
                  </motion.div>
                </motion.div>
              </motion.div>
              {brands.length > 0 ? (
                <motion.div
                  className="detail-grid"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true }}
                  className="brands-wrapper"
                >
                  <motion.h2 variants={brandVariants}>Brands Used</motion.h2>
                  <motion.div
                    className="detail-grid"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true }}
                    className="brand-grid"
                  >
                    {brands}
                  </motion.div>
                </motion.div>
              ) : null}
            </div>

            <motion.div
              variants={galleryVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.25 }}
              className="gallery-section"
            >
              <motion.h2 variants={itemVariants}>Gallery</motion.h2>
              <MotionGalleryGridWrapper
                variants={itemVariants}
                images={images.length}
              >
                <Gallery images={images} />
              </MotionGalleryGridWrapper>
            </motion.div>
          </div>
        </div>
      </ProjectPage>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      name
      id
      description
      headerImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR)
          url
        }
      }
      address {
        city
        state
      }
      materials_used {
        id
        name
      }
      slug {
        current
      }
      general_contractor {
        id
        name
      }
      fabrication {
        id
        name
      }
      brands {
        name
        url
        logoImage {
          asset {
            gatsbyImageData
          }
        }
      }
      galleryImages {
        imageCaption
        altText
        asset {
          thumb: gatsbyImageData(width: 195, height: 195)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
